import "./weight-graph.css";
import "./main.css";
import { useState } from "react";
import WeightTypeGoals from "./weight-type-goals.component";
import WeightTypeTable from "./weight-type-table.component";
import WeighTypeTableWeightGoal from "./weight-type-table-weight-goal.component";
import WeightAddWeightGoal from "./weight-add-weight-goal.component";
//images imports
import symbolCareDashboardHeaderOptionsGraphActive from "./../../assets/images/dashboard/symbol_care-dashboard-header-options-graph-active.svg";
import symbolCareDashboardHeaderOptionsGraphInactive from "./../../assets/images/dashboard/symbol_care-dashboard-header-options-graph-inactive.svg";
import symbolCareDashboardHeaderOptionsTableSummaryActive from "./../../assets/images/client/detail/symbol_care-dashboard-header-options-table-summary-active.svg";
import symbolCareDashboardHeaderOptionsTableSummaryInactive from "./../../assets/images/client/detail/symbol_care-dashboard-header-options-table-summary-inactive.svg";
import symbolCareDashboardHeaderOptionsTableDetailActive from "./../../assets/images/client/detail/symbol_care-dashboard-header-options-table-detail-active.svg";
import symbolCareDashboardHeaderOptionsTableDetailInactive from "./../../assets/images/client/detail/symbol_care-dashboard-header-options-table-detail-inactive.svg";
import symbolCareDashboardHeaderOptionsGoalsActive from "./../../assets/images/client/detail/symbol_care-dashboard-header-options-goals-active.svg";
import symbolCareDashboardHeaderOptionsGoalsInactive from "./../../assets/images/client/detail/symbol_care-dashboard-header-options-goals-inactive.svg";
import symbolCareDashboardStatusEdit from "./../../assets/images/client/detail/symbol_care-dashboard-status-edit.svg";
import symbolCareDashboardWeightFirstStart from "./../../assets/images/dashboard/symbol_care-dashboard-weight-first-start.svg";
import symbolCareDashboardWeightLast from "./../../assets/images/dashboard/symbol_care-dashboard-weight-last.svg";
import symbolCareDashboardWeightTrendLoss from "./../../assets/images/dashboard/symbol_care-dashboard-weight-trend-loss.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';




import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const WeightGraph = () => {

 const [activeTab, setActiveTab] = useState("type-graph");
 const [activeDayTab, setActiveDayTab] = useState("weight-thirty");
 const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
     //  Functions to handle Tab Switching
  const handleTab = (tabName) => {
    setActiveTab(tabName);
  };

  const handeDaysTab= (daysTabName)=>{
    setActiveDayTab(daysTabName);
  };

const handelSubmitDateHandler = () =>{
    console.log(startDate); console.log(endDate);
}

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Line Chart',
      },
    },
    maintainAspectRatio:false
  };

  const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "First dataset",
      data: [33, 53, 85, 41, 44, 65],
      fill: true,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)"
    }
  ]
};

  return (
    <div className="main">
    <section id="profile">
      <div className="card-full-weight">
    <div className="header">
        <h4>Weight</h4>
    </div>
    <div className="header-options">
        <div className="header-left">
            <div className="placeholder-graph">
            </div>
            <div className="placeholder-table">
            </div>
            <div className="placeholder-goals">
            </div>
        </div>
        <div className="header-center">
            <div className="type-graph" onClick={() => handleTab('type-graph')}>
                
                <img src={symbolCareDashboardHeaderOptionsGraphActive}  className={activeTab === "type-graph" ? "display-show" : "display-hidden"}  alt='' />
                <img src={symbolCareDashboardHeaderOptionsGraphInactive} className={activeTab !== "type-graph" ? "display-show" : "display-hidden"}  alt=''/>
            </div>
            <div className="type-goals" onClick={() => handleTab('type-goals')}>
                <img src={symbolCareDashboardHeaderOptionsTableSummaryActive} className={activeTab === "type-goals" ? "display-show" : "display-hidden"}  alt='' />
                <img src={symbolCareDashboardHeaderOptionsTableSummaryInactive} className={activeTab !== "type-goals" ? "display-show" : "display-hidden"} alt=''/>
            </div>
            <div className="type-table" onClick={() => handleTab('type-table')}>
                <img src={symbolCareDashboardHeaderOptionsTableDetailActive} className={activeTab === "type-table" ? "display-show" : "display-hidden"} alt=''/>
                <img src={symbolCareDashboardHeaderOptionsTableDetailInactive} className={activeTab !== "type-table" ? "display-show" : "display-hidden"} alt=''/>
            </div>
            <div className="type-table-weight-goal" onClick={() => handleTab('type-table-weight-goal')}>
                <img src={symbolCareDashboardHeaderOptionsGoalsActive} className={activeTab === "type-table-weight-goal" ? "display-show" : "display-hidden"} alt=''/>
                <img src={symbolCareDashboardHeaderOptionsGoalsInactive} className={activeTab !== "type-table-weight-goal" ? "display-show" : "display-hidden"} alt=''/>
            </div>
        </div>

        <div className="header-range">
            <div className="header-range-standard" style={activeDayTab === "weight-custom" ? {display:'none'} : {} }>
                <div id='7' data-rel='7day'   onClick={() => handeDaysTab('weight-seven')}   className={activeDayTab === "weight-seven" ? "range-button-low weight-seven range-button-standard-active" : "range-button-low weight-seven range-button-standard-inactive"} >
                    <h6>7 days</h6>
                </div>
                <div id='30' data-rel='30day' onClick={() => handeDaysTab('weight-thirty')} className={activeDayTab === "weight-thirty" ? "range-button-high weight-thirty range-button-standard-active" : "range-button-high weight-thirty range-button-standard-inactive"}>
                    <h6>30 days</h6>
                </div>
                <div onClick={() => handeDaysTab('weight-custom')}    className={activeDayTab === "weight-custom" ? "range-button-custom weight-custom range-button-standard-active" : "range-button-custom weight-custom range-button-standard-inactive"}>
                    <h6>Custom</h6>
                </div>
            </div>
            <div className="header-range-standard ml-2" style={activeDayTab === "weight-custom" ? {display:'none'} : {} }>
                <div  className={activeDayTab === "weight-all" ? "range-button-all range-button-standard-active" : "range-button-all"}   id='weight-all' onClick={() => handeDaysTab('weight-all')}>
                    <h6 style={activeDayTab === "weight-all" ? {} : {color:"#4c84ff"} }>All</h6>                  
                </div>
            </div>
            <div className="header-range-standard ml-2">
                <div className="range-button-all" id='add-weight-goal' onClick={() => handeDaysTab('add-weight-goal')}>
                    <img src={symbolCareDashboardStatusEdit} className="add-weight-goal" title="Add Weight Goal" alt=''/>
                </div>
            </div>

            <div className="header-range-custom"  style={activeDayTab === "weight-custom" ? {} : {display: "none"} }>
                <div className="range-button-start">
                <DatePicker selected={startDate} onChange={date => setStartDate(date)} startDate={startDate}  shouldCloseOnSelect={false} id ="weight_start_date"/>
                </div>
                <div className="range-button-end">
                    {/* <input type="text" className="datepicker to hasDatepicker" value="end" id="weight_end_date"/> */}
                    <DatePicker  selected={endDate} onChange={date => setEndDate(date)} endDate={endDate}  minDate={startDate}  shouldCloseOnSelect={false} id ="weight_end_date" />
                </div>
                <div className="range-button-submit weight-btn" style={{background: "#4d85ff"}} onClick={()=>handelSubmitDateHandler()}>
                    <h6>Submit</h6>
                </div>
                <div className="range-button-reset weight-reset" onClick={() => handeDaysTab('weight-reset')}>
                    <h6>Reset</h6>
                </div>
            </div>
        </div>
    </div>
    
    <div className="body">

        <div className={activeTab === "type-graph" ? "graph-body display-show" : "graph-body display-hidden"} >
            <div className="weight-chart-container">
                <div className="chartjs-size-monitor" style={{position: "absolute", left: 0, top: 0, right: 0, bottom: 0, overflow: "hidden", pointerEvents: "none", visibility: "hidden", zIndex: -1}}>
                    <div className="chartjs-size-monitor-expand" style={{position:"absolute",left:0,top:0,right:0,bottom:0,overflow:"hidden",pointerEvents:"none",visibility:"hidden",zIndex:-1}}>
                        <div style={{position:"absolute",width:1000000,height:1000000,left:0,top:0}}></div>
                    </div>
                    <div className="chartjs-size-monitor-shrink" style={{position:"absolute",left:0,top:0,right:0,bottom:0,overflow:"hidden",pointerEvents:"none",visibility:"hidden",zIndex:-1}}>
                        <div style={{position:"absolute",width:"200%",height:"200%",left:0, top:0}}></div>
                    </div>
                </div>
             <Line data={data} options={options} height="200px"width="200px"></Line>
            </div>
            <div className="weight-stats">
                <div className="module">
                    <img className="module-icon" src={symbolCareDashboardWeightFirstStart} alt=''/>
                    <div>
                        <h5>173 lbs</h5>
                        <h6><span>Start</span> weight</h6>
                    </div>
                </div>
                <div className="module">
                    <img className="module-icon" src={symbolCareDashboardWeightLast} alt=''/>
                    <div>
                        <h5>171 lbs</h5>
                        <h6>Last weight</h6>
                    </div>
                </div>
                <div className="module">
                        <img className="module-icon-loss" src={symbolCareDashboardWeightTrendLoss} style={{display: "block"}} alt=''/>
                    <div>
                        <h5>-1.16% in 493 days</h5>
                        <h6>Overall weight change</h6>
                    </div>
                </div>
            </div>
        </div>
        {activeTab=='type-goals' ? <WeightTypeGoals displayprop="display-show"/>: <WeightTypeGoals displayprop="display-hidden"/>}  
       {activeTab=='type-table' ? <WeightTypeTable displayprop="display-show"/>: <WeightTypeTable displayprop="display-hidden"/>} 
       {activeTab=='type-table-weight-goal' ? <WeighTypeTableWeightGoal displayprop="display-show"/>: <WeighTypeTableWeightGoal displayprop="display-hidden"/>} 
    </div>
</div>

     </section>
     { activeDayTab=='add-weight-goal' ? <WeightAddWeightGoal handeDaysTab={handeDaysTab}/>: ''}  
     </div>
  );
};

export default WeightGraph;
