// import SignUpForm from "../../components/sign-up-form/sign-up-form.component";
// import SignInForm from "../../components/sign-in-form/sign-in-form.component";

import "./login.styles.scss";

const Login = () => {
  return (
    <div className="login-container">
      <div class="login-header">Login</div>
      <div class="login-description">Login Credentials</div>
      <div>
        <input placeholder="email address" type="text" class="login-input" value="vidhya@3tandai.com" />
        <input placeholder="password" type="password" class="login-input" value="Passw0rd" />
      </div>
      <div>
        <span class="forgot-password">Forgot Password</span>
        <div>

        </div>
        <div className="login-submit-btn">
           <span class="login-submit-btn-text">Submit</span>
        </div>
      </div>
    </div>
  );
};

export default Login;
