import { Fragment, useContext } from 'react';
import { Outlet, Link } from 'react-router-dom';

import { UserContext } from '../../contexts/user.context';

import { ReactComponent as CrwnLogo } from '../../assets/crown.svg';
import { signOutUser } from '../../utils/firebase/firebase.utils';

import './navigation.styles.scss';
import Dashboard from '../../components/dashboard/dashboard-component';

const Navigation = () => {
  const { currentUser } = useContext(UserContext);

  return (
    <Fragment>
        <div className="container-fluid h-100">
        
        <div className="row h-100">
            <div className="col-2" id="green">

            <div className="headerLogo">
              <img src="https://digbi-cdn-01.s3.amazonaws.com/webapp-img-stage/images/v2/logo-digbi-health.svg" alt="" />
            </div>

            <div className="nav-menu"
            style={{
              backgroundColor: '#FFFFFF',
              borderRadius: '4px'
            }}    
            >
              <img src="https://digbi-cdn-01.s3.amazonaws.com/webapp-img-stage/images/v2/navigation/icon_nav-dashboard.svg" alt=""/>
              <h4>Dashboard</h4>
            </div>


            <div className="nav-menu">
            <img src="https://digbi-cdn-01.s3.amazonaws.com/webapp-img-stage/images/v2/navigation/icon_nav-me.svg" alt=""/>
              <h4>Me</h4>
            </div>
                


            
             
               
            </div>
            <div className="col-10" style={{padding: 0, backgroundColor:'#fff', minHeight: '100%'}}>
     
                {/* <nav className="navbar navbar-expand-lg
                                navbar-light bg-primary">
                    <a className="navbar-brand" href="#">Navbar</a>
                   
                    <button className="navbar-toggler" type="button"
                        data-toggle="collapse"
                        data-target="#navbarNavAltMarkup"
                        aria-controls="navbarNavAltMarkup"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
               
                    <div className="collapse navbar-collapse"
                        id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <a className="nav-item nav-link
                                active" href="#">
                               Home
                              </a>
                            <a className="nav-item nav-link" href="#">Features</a>
                            <a className="nav-item nav-link" href="#">Price</a>
                            <a className="nav-item nav-link" href="#">About</a>
                        </div>
                    </div>
                </nav> */}
                
           
                <Dashboard/>
 
            </div>
        </div>
    </div>

      {/* <div className=Name='navigation'>
        <Link className=Name='logo-container' to='/'>
          <CrwnLogo className=Name='logo' />
        </Link>
        <div className=Name='nav-links-container'>
          <Link className=Name='nav-link' to='/shop'>
            SHOP
          </Link>

          {currentUser ? (
            <span className=Name='nav-link' onClick={signOutUser}>
              SIGN OUT
            </span>
          ) : (
            <Link className=Name='nav-link' to='/auth'>
              SIGN IN
            </Link>
          )}
        </div>
      </div>
      <Outlet /> */}
    </Fragment>
  );
};

export default Navigation;
