import React from 'react';
 const WeightTypeTable = (props) => {
  return (
   <div  className={"table-body care-table-body " + props.displayprop}>
            <div className="table-container" id="weight_chart">
          <table className="table-standard"><tbody><tr>
                            <th><h5>Date</h5></th>
                            <th><h5>Timestamp</h5></th>
                            <th><h5>Change</h5></th>
                            <th><h5>Days Gap</h5></th>
                            <th><h5>Weight</h5></th>
                            <th><h5>Source</h5></th>
                            <th><h5>Overall</h5></th>
                          </tr>
          
                          <tr>
                            <td><h6>22 May 2023</h6></td>
                            <td><h6>02:59 PM</h6></td>
                            <td><h6 style={{margin: 0,paddingTop: 5,color:"#f5a623"}}>-2lbs | 1.18%</h6></td>
                            <td><h6>10d</h6></td>
                            <td><h6>171.00</h6></td>
                            <td><h6>Apple health</h6></td>
                            <td><h6>-2.29%</h6></td>
                          </tr>
                          <tr>
                            <td><h6>22 May 2023</h6></td>
                            <td><h6>02:59 PM</h6></td>
                            <td><h6 style={{margin: 0,paddingTop: 5,color:"#f5a623"}}>-2lbs | 1.18%</h6></td>
                            <td><h6>10d</h6></td>
                            <td><h6>171.00</h6></td>
                            <td><h6>Apple health</h6></td>
                            <td><h6>-2.29%</h6></td>
                          </tr>
                          <tr>
                            <td><h6>22 May 2023</h6></td>
                            <td><h6>02:59 PM</h6></td>
                            <td><h6 style={{margin: 0,paddingTop: 5,color:"#f5a623"}}>-2lbs | 1.18%</h6></td>
                            <td><h6>10d</h6></td>
                            <td><h6>171.00</h6></td>
                            <td><h6>Apple health</h6></td>
                            <td><h6>-2.29%</h6></td>
                          </tr>
                          <tr>
                            <td><h6>22 May 2023</h6></td>
                            <td><h6>02:59 PM</h6></td>
                            <td><h6 style={{margin: 0,paddingTop: 5,color:"#f5a623"}}>-2lbs | 1.18%</h6></td>
                            <td><h6>10d</h6></td>
                            <td><h6>171.00</h6></td>
                            <td><h6>Apple health</h6></td>
                            <td><h6>-2.29%</h6></td>
                          </tr>
                </tbody>
                </table>
                </div>
        </div>
    
  )
}

export default WeightTypeTable;
