import React from 'react';
 const WeighTypeGoals = (props) => {
  return (
    <div className={"goals-body " + props.displayprop}>
    <div className={"table-body frx-table-body " + props.displayprop} >
        <div className="table-container" id="frx-table-weight-chart">
            <table className="table-standard">
                  <tbody><tr>
                    <th><h5>Date</h5></th>
                    <th><h5>Change</h5></th>
                    <th><h5>Weight</h5></th>
                    <th><h5>Source</h5></th>
                    <th><h5>Overall</h5></th>
                  </tr>
                <tr>
                    <td className="weight-table-weekly-summary-td"><h6 className="weight-table-weekly-summary-h6">Mar 07 - 13</h6></td>
                    <td className="weight-table-weekly-summary-td"><h6 className="weight-table-weekly-summary-h6" style={{color:"#838597"}}>-- lbs | 0%</h6></td>
                    <td className="weight-table-weekly-summary-td"><h6 className="weight-table-weekly-summary-h6"></h6></td>
                    <td className="weight-table-weekly-summary-td"><h6 className="weight-table-weekly-summary-h6"></h6></td>
                    <td className="weight-table-weekly-summary-td"><h6 className="weight-table-weekly-summary-h6"></h6></td>
                  </tr>
                    <tr>
                        <td><h6>Mar 07</h6></td>
                        <td><h6> </h6></td>
                        <td><h6>175.00</h6></td>
                        <td><h6>trinchero</h6></td>
                        <td><h6>0.00</h6></td>
                    </tr>
                    <tr>
                    <td className="weight-table-weekly-summary-td"><h6 className="weight-table-weekly-summary-h6">Mar 07 - 13</h6></td>
                    <td className="weight-table-weekly-summary-td"><h6 className="weight-table-weekly-summary-h6" style={{color:"#838597"}}>-- lbs | 0%</h6></td>
                    <td className="weight-table-weekly-summary-td"><h6 className="weight-table-weekly-summary-h6"></h6></td>
                    <td className="weight-table-weekly-summary-td"><h6 className="weight-table-weekly-summary-h6"></h6></td>
                    <td className="weight-table-weekly-summary-td"><h6 className="weight-table-weekly-summary-h6"></h6></td>
                  </tr>
                    <tr>
                        <td><h6>Mar 07</h6></td>
                        <td><h6> </h6></td>
                        <td><h6>175.00</h6></td>
                        <td><h6>trinchero</h6></td>
                        <td><h6>0.00</h6></td>
                    </tr>
                    <tr>
                    <td className="weight-table-weekly-summary-td"><h6 className="weight-table-weekly-summary-h6">Mar 07 - 13</h6></td>
                    <td className="weight-table-weekly-summary-td"><h6 className="weight-table-weekly-summary-h6" style={{color:"#838597"}}>-- lbs | 0%</h6></td>
                    <td className="weight-table-weekly-summary-td"><h6 className="weight-table-weekly-summary-h6"></h6></td>
                    <td className="weight-table-weekly-summary-td"><h6 className="weight-table-weekly-summary-h6"></h6></td>
                    <td className="weight-table-weekly-summary-td"><h6 className="weight-table-weekly-summary-h6"></h6></td>
                  </tr>
                    <tr>
                        <td><h6>Mar 07</h6></td>
                        <td><h6> </h6></td>
                        <td><h6>175.00</h6></td>
                        <td><h6>trinchero</h6></td>
                        <td><h6>0.00</h6></td>
                    </tr>
                
                </tbody>
                </table>
                </div>
    </div>
</div>
    
  )
}

export default WeighTypeGoals;
