import "./dashboard-component.scss";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";

const Dashboard = () => {
  const [search,setSearch] = useState("");
  const [userList, setUserList] = useState([]);
  const [filterdUserList, setFilterdUserList] = useState([]);
  const getUserList = () => {
    let data = [
      {
        id: 1,
        name: "Bhupendra",
        email: "testone@gmail.com",
      },
      {
        id: 2,
        name: "Test two",
        email: "testtwo@gmail.com",
      },
      {
        id: 3,
        name: "Test 3",
        email: "testone@gmail.com",
      },
      {
        id: 4,
        name: "Test 4",
        email: "test@mail.com",
      },
      {
        id: 5,
        name: "Test 5",
        email: "testone@gmail.com",
      },
      {
        id: 6,
        name: "Test 6",
        email: "test@mail.com",
      },
      {
        id: 7,
        name: "Test one",
        email: "testone@gmail.com",
      },
      {
        id: 8,
        name: "Test two users",
        email: "test@mail.com",
      },
      {
        id: 9,
        name: "Test one",
        email: "testone@gmail.com",
      },
      {
        id: 10,
        name: "Test two users",
        email: "test@mail.com",
      },
      {
        id: 11,
        name: "Test one",
        email: "testone@gmail.com",
      },
      {
        id: 12,
        name: "Test two users",
        email: "test@mail.com",
      },
    ];
    setUserList(data);
    setFilterdUserList(data);
  };
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: "Email",
      selector: (row) => row.email,
    }
  ];
  useEffect(() => {
    console.log("hello world");
    getUserList();
  }, []);
    useEffect(()=>{
      console.log("hello world2");
      const result = userList.filter(user=>{
        return user.name.toLowerCase().match(search.toLocaleLowerCase());
      })
      console.log(result);
      if(result.length > 0){
      setFilterdUserList(result);
      }
    },[search])

  return (
    <DataTable
   title='User List' 
  columns={columns} 
  data={filterdUserList} 
  pagination 
  fixedHeader
  fixedHeaderScrollHeight="500px"
  selectableRows
  selectableRowsHighlight
  highlightOnHover
  // actions= {<button className="btn btn-sm btn-info">Export</button>}
  subHeader
  subHeaderComponent= {<input type="text" placeholder="Search Here" className="w-25 form-control"
  value= {search}
  onChange={ (e) => setSearch(e.target.value)}
  />}
  
  />
  );
};

export default Dashboard;
