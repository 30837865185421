import { Routes, Route } from 'react-router-dom';

import Home from './routes/home/home.component';
import Navigation from './routes/navigation/navigation.component';
import Login from './routes/login/login.component';
import Authentication from './routes/authentication/authentication.component';
import WeightGraph from './components/weight-graph/weight-graph.component'


const Shop = () => {
  return <h1>I am the shop page</h1>;
};

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Login />}>
     
        {/* <Route index element={<Home />} />
        <Route path='shop' element={<Shop />} />
        <Route path='auth' element={<Authentication />} /> */}
      </Route>
      <Route path='/dashboard' element={<Navigation />}>
        {/* <Route index element={<Home />} /> */}
        <Route path='shop' element={<Shop />} />
        <Route path='auth' element={<Authentication />} /> 
      </Route> 
      <Route path='/weight-graph' element={<WeightGraph/>} /> 
    </Routes>
  );
};

export default App;
