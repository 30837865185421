import React from 'react';
import symbolCareDashboardClose from "./../../assets/images/client/detail/symbol_care-dashboard-close.svg";
import symbolCareDashboardTaeTarget from "./../../assets/images/client/detail/symbol_care-dashboard-tae-target.svg";
 const WeightAddWeightGoal = (props) => {
  return (
    <section id="prompts" style={{display: "flex"}}>
        <div className="prompt-add-weight-goal" id="prompt-add-weight-goal" style={{display: "flex"}}>
            <img className="close" src={symbolCareDashboardClose} onClick={()=> props.handeDaysTab('weight-thirty')} />
            <div className="container">
                <div className="header">
                    <img src={symbolCareDashboardTaeTarget} style={{cursor: "default"}}/>
                    <h3>Add Weight Goal</h3>
                </div>
                <div className="editor">
                    <div className="form-group">
                        <label><b>Current Weight(lbs)</b></label>
                        <input value="206" id="current-weight" name="current_weight" type="text" className="form-control form-control-user" readOnly="" />

                    </div>
                    <div className="form-group">
                        <label><b>Weight goal</b></label>
                        <input value="Inactive" id="weight-goal-record" name="weight_goal_record" type="text" className="form-control form-control-user" readOnly="" />
                    </div>
                    <div className="form-group">
                        <label><b>Target Weight(lbs)</b></label>
                        <input value="" id="target-weight" name="target_weight" type="number" className="form-control form-control-user" placeholder="Enter Weight" step="any" required="" />
                    </div>
                    <div className="form-group">

                        <div className="input-group date" data-provide="datepicker" id="cc">
                            <label htmlFor="exampleFormControlInput1"><b>Target Date</b></label>
                            <input type="text" className="form-control form-control-user" placeholder="Select Target Date" id="target-date" name="target_date" style={{width: '100%'}} required="" />
                            <div className="input-group-addon">
                                <span className="glyphicon glyphicon-th"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" id="add-weight-goal-button">
                    <h5>Submit</h5>
                </button>
            </div>
        </div>
    </section>
  )
}
export default WeightAddWeightGoal;
