import React from 'react';
 const WeighTypeTableWeightGoal = (props) => {
  return (
    <div  className={"table-body care-table-body-weight-goal " + props.displayprop}>
            <div className="table-container" id="weight_chart_goal">
          <table className="table-standard"><tbody><tr>
                            <th><h5>Date</h5></th>
                            <th><h5>Start Weight</h5></th>
                            <th><h5>Goal</h5></th>
                            <th><h5>Change</h5></th>
                            <th><h5>Target Date</h5></th>
                            <th><h5>Achieved Date</h5></th>
                            <th><h5>Status</h5></th>
                            <th><h5>Days Left</h5></th>
                            <th><h5>Coach/User</h5></th>
                          </tr>
          
                          <tr>
                            <td><h6>07 Mar 2022</h6></td>
                            <td><h6>0</h6></td>
                            <td><h6>0</h6></td>
                            <td><h6 style={{margin: 0,paddingTop: 5,color:"#838597"}}>0lbs | 0%</h6></td>
                            <td><h6>Mar 07</h6></td>
                            <td><h6>N/A</h6></td>
                            <td><h6 style={{color:"#4E73DF"}}>NOT INTERESTED</h6></td>
                            <td><h6>0 Days</h6></td>
                            <td><h6>User</h6></td>
                          </tr>
                </tbody></table></div>
        </div>
  )
}

export default WeighTypeTableWeightGoal;
